/* Order Create, Vue Component */
<template>
  <div>
    <member-summary v-if="member" title="Create Order For Member" :member="member"></member-summary>

    <v-card v-if="entity && !loading" id="order-create" class="entity-create mt-6 rounded-lg">

        <v-form ref="form">
        <v-simple-table dense class="rounded-t-lg">
          <thead>
            <tr>
              <th colspan="2" class="rounded-t-lg">Order Details</th>
            </tr>
          </thead>
          <tbody>

        <tr>
          <td>Product</td>
          <td><ProductLookup v-model="entity.ProductId" :rules="[]" :error-messages="serverErrors.filter(x => x.field === 'ProductId').map(x => x.error)" /></td>
        </tr>

        <tr>
          <td>Delivery Address</td>
          <td><AddressSelect :filter="{ MemberId: memberId, IsDeleted: false }" v-model="entity.DeliveryAddressId" :rules="[validators.required]" :error-messages="serverErrors.filter(x => x.field === 'DeliveryAddressId').map(x => x.error)" /></td>
        </tr>

        <tr>
          <td>Billing Address</td>
          <td><AddressSelect :filter="{ MemberId: memberId, IsDeleted: false }" v-model="entity.BillingAddressId" :rules="[validators.required]" :error-messages="serverErrors.filter(x => x.field === 'BillingAddressId').map(x => x.error)" /></td>
        </tr>

        <tr>
          <td>Retailer</td>
          <td><v-text-field
        label="Retailer"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'RetailerName').map(x => x.error)"
        v-model="entity.RetailerName"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Retailer Reference</td>
          <td><v-text-field
        label="Retailer Reference"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'RetailerReference').map(x => x.error)"
        v-model="entity.RetailerReference"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Retailer Tracking Reference</td>
          <td><v-text-field
        label="Retailer Tracking Reference"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'RetailerTrackingReference').map(x => x.error)"
        v-model="entity.RetailerTrackingReference"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Custom Length</td>
          <td><v-text-field
        label="Custom Length"
        solo-inverted
        type="number"
        flat
        :error-messages="serverErrors.filter(x => x.field === 'CustomLength').map(x => x.error)"
        v-model="entity.CustomLength"
        :rules="[validators.integerValidator]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Custom Width</td>
          <td><v-text-field
        label="Custom Width"
        solo-inverted
        type="number"
        flat
        :error-messages="serverErrors.filter(x => x.field === 'CustomWidth').map(x => x.error)"
        v-model="entity.CustomWidth"
        :rules="[validators.integerValidator]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Custom Height</td>
          <td><v-text-field
        label="Custom Height"
        solo-inverted
        type="number"
        flat
        :error-messages="serverErrors.filter(x => x.field === 'CustomHeight').map(x => x.error)"
        v-model="entity.CustomHeight"
        :rules="[validators.integerValidator]"
      ></v-text-field>
  </td>
        </tr>

        <tr>
          <td>Item Description</td>
          <td><v-text-field
        label="Item Description"
        solo-inverted
        flat
        :error-messages="serverErrors.filter(x => x.field === 'ItemDescription').map(x => x.error)"
        v-model="entity.ItemDescription"
        :rules="[]"
      ></v-text-field>
  </td>
        </tr>


          </tbody>
        </v-simple-table>

        <v-card-text class="d-flex align-center flex-wrap py-0">
          <v-alert
            v-if="serverErrorMessage"
            type="error"
          >
            {{ serverErrorMessage }}
          </v-alert>
        </v-card-text>

        <v-card-text class="d-flex align-center flex-wrap pb-0 mt-6">
        <v-row class="align-center pb-5">
          <v-col>
          <v-btn
            color="success"
            class="me-3"
            @click="Save"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiContentSaveOutline  }}
            </v-icon>
            <span>Create Order</span>
          </v-btn>
          </v-col>
          <v-col class="text-right">
          <v-btn
            color="secondary"
            class="me-3"
            :to="{ name: 'member-findforbooking' }"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiClose }}
            </v-icon>
            <span>Cancel</span>
          </v-btn>
          </v-col>
        </v-row>
        </v-card-text>

        </v-form>

    </v-card>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
import * as FrameworkValidators from '@core/utils/validation'
import * as Validators from '@/datahelpers/validation'
import DateField from '@/views/helpers/DateField'
// import OrderStatusLookup from '@/views/orderstatus/OrderStatusLookup'
import ProductLookup from '@/views/product/ProductLookup'
// import ManifestLookup from '@/views/manifest/ManifestLookup'
// import MemberLookup from '@/views/member/MemberLookup'
//import AddressLookup from '@/views/address/AddressLookup'
import AddressSelect from '@/views/address/AddressSelect'
import MemberSummary from '@/views/member/components/MemberSummary.vue'

import {
  mdiContentSaveOutline,
  mdiClose
} from '@mdi/js'
import { ref, watch, computed, expose } from '@vue/composition-api'

export default {
  components: {
    DateField,
    // OrderStatusLookup,
		ProductLookup,
		// ManifestLookup,
		// MemberLookup,
		// AddressLookup,
    AddressSelect,
    MemberSummary
  },
  setup() {

    const { route, router  } = useRouter();

    const entity = ref({});
    const loading = ref(false);
    const form = ref(null);
    const serverErrors = ref([]);
    const serverErrorMessage = ref(null);

    const member = computed(() => store.state.app.Member);
    const fetchMember = (id) => {
      store
        .dispatch('app/fetchMember', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'member-list' });
        })
    }
    fetchMember(route.value.params.id);

    const Save = function(e) {

      serverErrorMessage.value = null;
      serverErrors.value = [];

      const valid = form.value.validate();

      if (!valid) {
        return;
      }

      loading.value = true;

      console.log("Save");
      store
        .dispatch('app/createOrderForMember', {
          entity: Object.assign(entity.value, {MemberId: route.value.params.id})
        })
        .then(response => {
          loading.value = false;
          router.push({ name: 'order-bookitem', params: { id: response.data.Id } });
        })
        .catch((error) => {
          loading.value = false;
          if (error.error) {
            if (error.message) serverErrorMessage.value = error.message;
            if (error.errors) serverErrors.value = error.errors
          } else {
            console.log(error);
          }
        });
    };

    const validators = {
      ...FrameworkValidators,
      ...Validators
    };

    return {
      memberId: route.value.params.id,
      member,
      form,
      Save,
      loading,
      entity,
      Display,
      icons: {
        mdiContentSaveOutline ,
        mdiClose
      },
      validators,
      serverErrorMessage,
      serverErrors
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-create::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td:last-child {
  padding-right: 0;
}
.entity-create::v-deep .v-data-table th:last-child {
  width: 66%;
}
.entity-create::v-deep  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent;
}
.entity-create::v-deep .v-input:not(.error--text) .v-messages {
  min-height: unset;
}
.entity-create::v-deep .v-input .v-messages.error--text {
  margin-bottom: 8px;
}
.entity-create::v-deep .v-input:not(.error--text) .v-text-field__details {
  min-height: unset;
  margin-bottom: 0;
}
.entity-create::v-deep .v-input:not(.error--text) .v-input__slot {
  margin: 0;
  border-radius: 0;
}
.entity-create::v-deep .v-input--selection-controls {
  margin: 10px;
}
.entity-create::v-deep table {
  border-bottom: 1px solid rgba(94, 86, 105, 0.14);
}
</style>

